import React from "react";

import tour from "../utils/tour.jpg";

import "./TourPage.css";

export default function TourPage(props) {
  return (
    <div className="page-wrapper _tour">
      <div className="page-container">
        <header>
          <h1>Down the Lane Tour</h1>
          <p className="tour-subtitle">Coming to a lane near you.</p>
        </header>

        <img
          src={tour}
          className="show-poster"
          style={{ maxWidth: "70%", marginLeft: "auto", marginRight: "auto", marginBottom: "5rem" }}
        />

        <center>
          <h1>Tickets</h1>
        </center>

        <div className="tour-date">
          <h2>Washington, DC - The Atlantis</h2>
          <h3>January 25, 2025</h3>
          <a target="_blank" href="https://www.ticketmaster.com/strutman-lane-washington-district-of-columbia-01-25-2025/event/15006151CFD7395C?_gl=1*10g80t6*_ga*MTgxMDExMjgyOC4xNzI5MjY0NDEw*_ga_CXPRCMPWXK*MTcyOTI2NDQxMC4xLjAuMTcyOTI2NDQxMC42MC4wLjA.">
            <button>Get Tickets</button>
          </a>
        </div>

        <div className="tour-date">
          <h2>Philadelphia, PA - Silk City</h2>
          <h3>January 31, 2025</h3>
          <a target="_blank" href="https://www.etix.com/ticket/p/83437629/strutman-lane-philadelphia-silk-city?partner_id=100">
            <button>Get Tickets</button>
          </a>
        </div>

        <div className="tour-date">
          <h2>New York, NY - Mercury Lounge</h2>
          <h3>February 2, 2025</h3>
          <a target="_blank" href="https://www.ticketmaster.com/strutman-lane-new-york-new-york-02-02-2025/event/00006152C6CB5392">
            <button>Get Tickets</button>
          </a>
        </div>

        <div className="tour-date">
          <h2>Washington, DC - The Hamilton</h2>
          <h3>February 5, 2025</h3>
          <p>Opening for Dustbowl Revival</p>
          <a target="_blank" href="https://www.seetickets.us/event/dustbowl-revival/598739">
            <button>Get Tickets</button>
          </a>
        </div>

        <div className="tour-date">
          <h2>Providence, RI - AS220 Main Stage</h2>
          <h3>February 7, 2025</h3>
          <a target="_blank" href="https://www.zeffy.com/ticketing/cc3b1835-0747-4922-a348-3daa590701a3">
            <button>Get Tickets</button>
          </a>
        </div>

        <div className="tour-date">
          <h2>Boston, MA - The Middle East</h2>
          <h3>February 8, 2025</h3>
          <a target="_blank" href="https://www.ticketweb.com/event/strutman-lane-middle-east-upstairs-tickets/13922403?irgwc=1&clickid=WZnz-yU6wxyKUUNUHfQtOzY3UkCR8NwlIzXTQg0&camefrom=CFC_BUYAT_253158&impradid=253158&REFERRAL_ID=tmfeedbuyat253158&wt.mc_id=aff_BUYAT_253158&utm_source=253158-Facebook-%20TM&utm_medium=affiliate&ircid=4272">
            <button>Get Tickets</button>
          </a>
        </div>
      </div>
    </div>
  );
}
